import swal from 'sweetalert';

export function onError(error) {
    let message = error.toString();

    // Auth errors
    if (!(error instanceof Error) && error.message) {
      message = error.message;
    }

    swal({
      title: "Oops!",
      text: message,
      icon: "error",
      button: "Close",
    });
  }