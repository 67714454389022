import Container from '@material-ui/core/Container';
import { createTheme, makeStyles, ThemeProvider } from '@material-ui/core/styles';
import { Auth } from "aws-amplify";
import React, { useEffect, useState, useLayoutEffect } from "react";
import "./App.css";
import Navbar from "./components/app/navbar/Navbar";
import { AppContext } from "./components/libs/context-lib";
import { onError } from "./components/libs/error-lib";
import LoadingBackdrop from "./components/modules/LoadingBackdrop";
import Routes from "./components/routes/Routes";
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import Footer from './components/app/footer/Footer';
import { Grid } from '@material-ui/core';
import { useHistory, useLocation } from "react-router-dom";
import RoutePaths from "./routePaths";
import { onInfo } from './components/libs/info-lib';

const theme = createTheme({
  palette: {
    error:        { main: '#f5365c',  hover:'#9a0036' },
    success:      { main: '#66bb6a',  hover:'#388e3c' },
    warning:      { hover: '#fb6340', main:'#F7D330' },
    archived:     { main: '#5e72e4',  hover:'#dfeaed' },
    featured:     { main: '#f5a624',  hover:'#f9cb7d' },
    bkBlue:       { main: '#63B9F3',  hover:'#679ECE', white: '#F1F3E4', tertiary:'#32325d', tertiaryHover:'#7c7ca7'},
    title:        { main: '#5d5d6b',  hover:'#9898a1' },
    subTitle:     { main: '#cbced5',  hover:'#d1d4db' },
    category:     { main: '#dce6eb',  hover:'#e9f0f4' },
  },
});

const drawerWidth = 240;


const useStyles = makeStyles((theme) => ({
  root: {
    // background:'#f1f5f8',
    width: '100%',
    height:'100%',
    minHeight:'100vh',
    overflow:'hidden'
  },
  drawer:{
    [theme.breakpoints.up('sm')]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  navBar:{
    zIndex: theme.zIndex.drawer + 1,
  },
  wrapper:{
    display: 'inline-block',
    width:'100%',
    position:'relative',
    clear:'both'
  },
  containerWrapper:{
    minHeight:'100vh',
    padding:0,
    margin:0
  }
}));

function Alert(props) {
  return <MuiAlert elevation={8} variant="filled" {...props} />;
}

export default function App() {
  const classes = useStyles();
  let history = useHistory();
  const [isAuthenticated, userHasAuthenticated] = useState(false);
  const [isAuthenticating, setIsAuthenticating] = useState(true);
  const [loadingBackdrop, setLoadingBackdrop] = useState(false);
  const location = useLocation().pathname;
  const [snackState, setSnackState] = useState({
    opened: false,
    severity: "",
    message: ""
  });
  const [isCrappyIE, setIsCrappyIE] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [isMobileDevice, setIsMobileDevice] = useWindowSize();

  function useWindowSize() {
    const [size, setSize] = useState([0, 0]);
    useLayoutEffect(() => {
      function updateSize() {
        window.innerWidth <= 820 ? setIsMobile(true) : setIsMobile(false);
      }
      window.addEventListener('resize', updateSize);
      updateSize();
      return () => window.removeEventListener('resize', updateSize);
    }, []);
    return size;
  }

  useEffect(() => {
    if (
      typeof window !== "undefined" &&
      window.navigator.msSaveOrOpenBlob &&
      window.Blob
    ) {
      setIsCrappyIE(true);
    }
    /** Fetch Events */
    onLoad();
  }, []);

  async function onLoad() {
    try {
      await Auth.currentSession()
      .then((data) => {
          const dateNow = new Date();
          const authTime = new Date(data.accessToken.payload.auth_time*1000);
          const msInHour = 1000 * 60 * 60;

          if((Math.round(Math.abs(dateNow - authTime) / msInHour)) >= 24 ){
            history.push(RoutePaths.home.path);
            onInfo("Your session has expired.", "You must log back in to manage events.");
            Auth.signOut();
            return userHasAuthenticated(false);
          }
          else
            return userHasAuthenticated(true);
      });
    }
    catch(e) {
      if (e !== 'No current user') {
        onError(e);
        userHasAuthenticated(false);
      }
    }finally{
      setIsAuthenticating(false);
    }
  }

  const handleAlertClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setSnackState({opened: false});
  };


  return (
    !isAuthenticating && (
      <div className={classes.root}>
        <ThemeProvider theme={theme}>
          <AppContext.Provider value={
              {
                authentication: {isAuthenticated, userHasAuthenticated},
                loading: {loadingBackdrop, setLoadingBackdrop},
                snackbar: {snackState, setSnackState},
                isIE: {isCrappyIE, setIsCrappyIE},
                detectMobile:{isMobile}
              }
            }>
            <LoadingBackdrop/>
            <Grid container spacing={0} className={classes.containerWrapper}>
              <Grid item xs={12} style={{padding: 0, height:'5.2rem'}}>
                <Navbar className={classes.navBar} location={location}/>
              </Grid>
              <Grid item xs={12} style={{padding: 0}}>
                <Snackbar open={snackState.opened} autoHideDuration={6000} onClose={handleAlertClose}>
                  <Alert onClose={handleAlertClose} severity={snackState.severity}>
                    {snackState.message}
                  </Alert>
                </Snackbar>
                <Routes/>
              </Grid>
              <Footer/>
            </Grid>
          </AppContext.Provider>
        </ThemeProvider>
      </div>
    )
  );
};