import React, { useRef, useState, useEffect } from "react";
import { makeStyles } from '@material-ui/core/styles';
import { Container, Divider, Grid } from "@material-ui/core";
import FacebookIcon from '@material-ui/icons/Facebook';

const useStyles = makeStyles((theme) => ({
    root:{
        position: 'relative',
        float: 'left',
        clear: 'both',
        width: '100%',
        minHeight: '5rem',
        background: '#32325d',
        // marginTop:'2rem',
        display: 'flex',
        justifyContent: 'space-evenly',
        alignItems: 'center',
    },
    anchor:{
        color: '#35b8be',
        textDecoration: 'underline'
    },
    footing:{
        color:'#353448',
        height: '100%',
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        color:'white'
    },
    facebook:{
        color:'white', fontSize:'1.6rem'
    }
}));

export default function Footer() {
    const classes = useStyles();

    return(
        <>
            <Container maxWidth="xl" className={classes.root}>
                <div className={classes.footing}>
                    <div>
                        <span>Built by <a className={classes.anchor} target="_blank" href="https://www.linkedin.com/in/sbuckley136/" rel="noopener">Stephen Buckley</a>  · See <a className={classes.anchor} target="_blank" href="https://www.celtichorizontours.com" rel="noopener">Celtic Horizon Tours</a> for tour info</span>
                    </div>
                    <div>
                    <a className={classes.anchor} target="_blank" href="https://www.facebook.com/groups/180049192012672/?ref=br_rs" rel="noopener"><FacebookIcon className={classes.facebook}/></a>
                    </div>
                </div>
            </Container>
        </>
    );
}