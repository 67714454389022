import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";

import Loadable from 'react-loadable';

import asyncComponent from "../chunks/AsyncComponent";
import LoadingComponent from "../chunks/LoadingComponent";

import AuthenticatedRoute from "./AuthenticatedRoute";
import UnauthenticatedRoute from "./UnauthenticatedRoute";

import RoutePaths from "../../routePaths";

const Dashboard   = asyncComponent(() => import("../app/home/MemberHomepage"));
const Home        = asyncComponent(() => import("../app/home/NonMemberHomepage"));
const NotFound    = asyncComponent(() => import("../app/404/NotFound"));
const SignUp      = asyncComponent(() => import("../app/authentication/SignUp"));
const Login       = asyncComponent(() => import("../app/authentication/Login"));
const CreateEvent = asyncComponent(() => import("../app/events/CreateEvent"));
const EditEvent   = asyncComponent(() => import("../app/events/EditEvent"));

export default ({ childProps }) =>
    <Switch>
          <Route                  exact path={RoutePaths.home.path}                     component={Home}        props={childProps}/>
          <UnauthenticatedRoute   exact path={RoutePaths.signin.path}                   component={Login}       props={childProps}/>
          <AuthenticatedRoute     exact path={RoutePaths.signup.path}                   component={SignUp}      props={childProps}/>
          <AuthenticatedRoute     exact path={RoutePaths.members.path}                  component={Dashboard}   props={childProps}/>
          <AuthenticatedRoute     exact path={RoutePaths.members.new}                   component={CreateEvent} props={childProps}/>
          <AuthenticatedRoute     exact path={RoutePaths.members.edit}                  component={EditEvent}   props={childProps}/>
          <Route                        path="*"                                        component={NotFound}    props={childProps}/>
    </Switch>
;