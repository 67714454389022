import React from "react";

import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Link from '@material-ui/core/Link';
import AccountCircle from '@material-ui/icons/AccountCircle';
import MenuItem from '@material-ui/core/MenuItem';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuList from '@material-ui/core/MenuList';

import { Link as RouterLink, useHistory } from 'react-router-dom';

import { useAppContext } from "../../libs/context-lib";
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { Auth } from "aws-amplify";

import { useMediaQuery } from 'react-responsive';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import Logo from "../../images/BkLogo.jpg";
import { Divider } from "@material-ui/core";

import RoutePaths from "../../../routePaths";
import { onSuccess } from "../../libs/success-lib";

import ClearIcon from '@material-ui/icons/Clear';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  titleWrapper: {
    textDecoration: 'none',
    position: 'relative',
    float: 'left',
    textAlign: 'left',
    '&:hover':{
      textDecoration:'none'
    },
  },
  navButton:{
    background: 'transparent',
    fontWeight:700,
    color:'black',
    padding:'.5rem',
    transition:'color .2s ease-in-out',
     '&:hover':{
       background: 'transparent !important',
       border:'none !important',
       color:theme.palette.bkBlue.main,
       transition:'color .2s ease-in-out'
     },
  },
  navText:{
    background: 'transparent',
    fontWeight:700,
    color:'black',
    padding:'.5rem',
    transition:'color .2s ease-in-out',
     '&:hover':{
       background: 'transparent !important',
       border:'none !important',
       color:theme.palette.bkBlue.main,
       transition:'color .2s ease-in-out'
     },
  },
  appBar:{
    background:'white',
    boxShadow: 'rgb(0 0 0 / 24%) 0px 3px 8px',
    padding:'0.5rem',
    height:'5.2rem'
  },
  navTitleWrapper:{
    display:'flex',
    justifyContent :'space-between',
    flexGrow: 1,
    textTransform:'uppercase',
    alignItems:'center',
    '& *':{
      // position:'relative',
      // float:'left'
    }
  },
  bkLogo:{
    marginRight:'1.5rem',
    marginLeft:'1.5rem'
  },
  navText:{
    transition:'all .15s linear',
    fontSize:'0.8rem',
    fontWeight:'400',
    color:'#172b4d'
  },
  menuItem:{
    fontWeight:'500',
    padding:'0.6rem',
    paddingRight:'1rem',
    paddingLeft:'1rem',
    color:'black',
  },
  menuItemSignOut:{
    color: theme.palette.error.main,
    fontWeight:'bold',
    '&:hover':{
      background:theme.palette.error.main,
      color:'white'
    }
  },
  iconButton:{
    width:'6rem',
    '&:hover':{
      background:'none'
    }
  },
  links:{
    textDecoration:'none',
    color:'inherit',
    '&:hover':{
      textDecoration:'none'
    }
  },
  drawerIcon:{
    cursor:'pointer',
    color:'#172b4d'
  }
}));

export default function Navbar(...props) {
  const classes = useStyles();
  const history = useHistory();
  const [drawer, setDrawer] = React.useState(false);
  const { authentication } = useAppContext();
  const { isAuthenticated } = authentication;
  const { userHasAuthenticated } = authentication;
  const { detectMobile } = useAppContext();
  const { isMobile } = detectMobile;
  const location = props[0].location;
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  function handleListKeyDown(event) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    }
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;

    setDrawer(false);
  }, [open]);


  async function handleLogout() {
    await Auth.signOut();
    userHasAuthenticated(false);
    history.push(RoutePaths.home.path);
    onSuccess("You've logged out.")
  }

  function commonButtons(){
    return(
      <>
        <Button color="inherit"><Link component={RouterLink} to={RoutePaths.home.path} className={classes.navButton}>Home</Link></Button>
        <Button color="inherit"><Link component={RouterLink} to={RoutePaths.home.about} className={classes.navButton}>About</Link></Button>
        <Button color="inherit"><Link component={RouterLink} to={RoutePaths.home.events} className={classes.navButton}>Events</Link></Button>
        <Button color="inherit"><Link component={RouterLink} to={RoutePaths.home.committee} className={classes.navButton}>Committee</Link></Button>
        <Button disableFocusRipple disableRipple><Link component={RouterLink} to={RoutePaths.home.contact} className={classes.navButton}>Contact</Link></Button>
      </>
    );
  }

  return (
    <Typography component="div" className={classes.root}>
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar>
          <Typography component="div" className={classes.navTitleWrapper}>
            <Link component={RouterLink} to={RoutePaths.home.path} className={classes.links}>
              <Typography component="img" src={Logo} className={classes.bkLogo}/>
            </Link>
            {
              !isMobile ?
                <h4 style={{textShadow:'0 0 black'}}>Blue Knights</h4>
                :
                ""
            }
            {isAuthenticated ? (
                <>
                  <IconButton
                      ref={anchorRef}
                      aria-controls={open ? 'menu-list-grow' : undefined}
                      aria-haspopup="true"
                      onClick={handleToggle}
                      className={classes.iconButton}
                      disableFocusRipple disableRipple
                    >
                      <Typography component="div">
                        <AccountCircle fontSize="medium" style={{width:'1.2em',height:'1.2em', color:'#172b4d'}}/>
                        <Typography component="div" style={{display:'flex', justifyContent:'stretch,', verticalAlign:'middle'}}>
                          <Typography component="span" style={{color:'#172b4d'}}>Me</Typography>
                          <ArrowDropDownIcon style={{color:'#172b4d'}}/>
                        </Typography>
                      </Typography>
                    </IconButton>
                    <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal placement="bottom-end" >
                      {({ TransitionProps, placement }) => (
                        <Grow
                          {...TransitionProps}
                          style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'bottom end' }}
                        >
                          <Paper elevation={4}>
                            <ClickAwayListener onClickAway={handleClose}>
                              <MenuList autoFocusItem={open} id="menu-list-grow" onKeyDown={handleListKeyDown}>
                                <Link component={RouterLink} to={RoutePaths.home.path} className={classes.links}>
                                  <MenuItem className={`${classes.menuItem}`} onClick={handleClose}>
                                    Home
                                  </MenuItem>
                                </Link>
                                <Link component={RouterLink} to={RoutePaths.members.path} className={classes.links}>
                                  <MenuItem className={`${classes.menuItem}`} onClick={handleClose}>
                                    Admin Page
                                  </MenuItem>
                                </Link>
                                <Link component={RouterLink} to={RoutePaths.members.new} className={classes.links}>
                                  <MenuItem className={`${classes.menuItem}`}  onClick={handleClose}>
                                    Create New Event
                                  </MenuItem>
                                </Link>
                                <Divider/>
                                <MenuItem className={`${classes.menuItem} ${classes.menuItemSignOut}`} onClick={()=>{
                                  setOpen(false);
                                  handleLogout();
                                }}>
                                  <Link component={RouterLink} to={RoutePaths.home.path} className={classes.links}>Sign Out</Link>
                                </MenuItem>
                              </MenuList>
                            </ClickAwayListener>
                          </Paper>
                        </Grow>
                      )}
                    </Popper>
                  </>
                ) : (
                  <>
                    {
                      location == RoutePaths.signin.path ?
                      <Link component={RouterLink} to={RoutePaths.home.path} className={classes.navButton}><Button color="inherit" style={{padding:'1rem'}}>Home</Button></Link>
                      :
                      <Link component={RouterLink} to={RoutePaths.signin.path} className={classes.navButton}><Button color="inherit" style={{padding:'1rem'}}>Sign In</Button></Link>
                    }
                  </>
              )
            }
          </Typography>
        </Toolbar>
      </AppBar>
    </Typography>
  );
}