import swal from 'sweetalert';

export function onSuccess(input) {
    let message = input.toString();

    swal({
      title: "Good Job!",
      text: message,
      icon: "success",
      button: "Dismiss",
    });
  }