const RoutePaths = {
    home:{
        path:"/",
        about:'/#about-us',
        events:'/#events',
        committee:'/#committee',
        contact:'/#contact-us'
    },
    members:{
        path:"/admin",
        new: "/admin/create-new-event",
        editLink: "/admin/events/",
        edit: "/admin/events/:id",
    },
    signin:{
        path:"/sign-in"
    },
    signup:{
        path:"/members/sign-up"
    },
};

export default RoutePaths;