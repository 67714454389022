import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import { useAppContext } from '../libs/context-lib';


const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
    backgroundColor:'#000000cc',
  },

}));

export default function LoadingBackdrop({
    ...props
  }) {
  const classes = useStyles();

  const { loading } = useAppContext();
  const { loadingBackdrop } = loading;

  return (
    <div>
      <Backdrop className={classes.backdrop} open={loadingBackdrop}>
          <div style={{margin:'0 auto'}}>
              <div style={{textAlign:'center'}}>
                <CircularProgress size={60} color="inherit" />
              </div>
              <div>
                <span>Please wait ...</span>
              </div>
          </div>
      </Backdrop>
    </div>
  );
}