const config = {
    s3: {
      REGION: "eu-west-1",
      BUCKET: "blueknights-events",
    },
    apiGateway: {
      REGION: "eu-west-1",
      // URL: "https://q2dfwtv0vh.execute-api.eu-west-1.amazonaws.com/dev",
      URL: "https://cqbvouckcg.execute-api.eu-west-1.amazonaws.com/prod",
    },
    cognito: {
      REGION: "eu-west-1",
      USER_POOL_ID: "eu-west-1_qkgoRiFHX",
      APP_CLIENT_ID: "1936sv2qk0p3op68fsq7jlj057",
      IDENTITY_POOL_ID: "eu-west-1:593cf2ad-e949-45fc-95d3-119d49b6187a",
    },
  };

  export default config;